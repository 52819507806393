import { AfterViewInit, Component, OnInit } from '@angular/core';
declare var Tapjsli: any; // Declare Tapjsli to avoid TypeScript errors
@Component({
  selector: 'app-tap-payment',
  templateUrl: './tap-payment.component.html',
  styleUrls: ['./tap-payment.component.css']
})
export class TapPaymentComponent implements OnInit, AfterViewInit {

 
  private tap: any;
  private card: any;

  ngOnInit(): void {
    // This will run once the component is initialized
    this.tap = Tapjsli('pk_test_HR3G5J7y6cd8gzDmluQE1UtX'); // Your public key here
  }

  ngAfterViewInit(): void {
    // This will run after the component's view has been fully initialized
    this.initializeTapPayment();
  }

  initializeTapPayment(): void {
    const elements = this.tap.elements({});

    const style = {
      base: {
        color: '#535353',
        lineHeight: '18px',
        fontFamily: 'sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: 'rgba(0, 0, 0, 0.26)',
          fontSize: '15px'
        }
      },
      invalid: {
        color: 'red'
      }
    };

    const labels = {
      cardNumber: "Card Number",
      expirationDate: "MM/YY",
      cvv: "CVV",
      cardHolder: "Card Holder Name"
    };

    const paymentOptions = {
      currencyCode: ["BHD"],
      labels: labels,
      TextDirection: 'ltr',
      paymentAllowed: ['VISA', 'MASTERCARD', 'AMERICAN_EXPRESS']
    };

    this.card = elements.create('card', { style: style }, paymentOptions);
    this.card.mount('#element-container');

    this.card.addEventListener('change', (event: any) => {
      if (event.loaded) {
        console.log("UI loaded :" + event.loaded);
        console.log("current currency is :" + this.card.getCurrency());
      }
      const displayError = document.getElementById('error-handler');
      if (event.error) {
        displayError!.textContent = event.error.message;
      } else {
        displayError!.textContent = '';
      }
    });

    this.card.addEventListener('change', (event: any) => {
      if (event.BIN) {
        console.log(event.BIN);
      }
      const displayError = document.getElementById('card-errors');
      if (event.error) {
        displayError!.textContent = event.error.message;
      } else {
        displayError!.textContent = '';
      }
    });
  }

  onSubmit(event: Event): void {
    event.preventDefault();
    console.log('Tap object:', this.tap); // Add this line
    console.log('Card object:', this.card); // Add this line
    this.tap.createToken(this.card).then((result: any) => {
      console.log(result);
      if (result.error) {
        const errorElement = document.getElementById('error-handler');
        errorElement!.textContent = result.error.message;
      } else {
        const successElement = document.getElementById('success');
        successElement!.style.display = "block";
        const tokenElement = document.getElementById('token');
        tokenElement!.textContent = result.id;
        this.tapTokenHandler(result.id);
      }
    });
  }

  tapTokenHandler(token: string): void {
    const form = document.getElementById('payment-form') as HTMLFormElement;
    const hiddenInput = document.createElement('input');
    hiddenInput.setAttribute('type', 'hidden');
    hiddenInput.setAttribute('name', 'tapToken');
    hiddenInput.setAttribute('value', token);
    form.appendChild(hiddenInput);

    // Submit the form
    form.submit();
  }
  
}
