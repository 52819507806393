import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, retry, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AboutUs } from '../models/about-us';
import { Branches } from '../models/branches';
import { Claim } from '../models/claim';
import { ClaimSection } from '../models/claim-section';
import { FinancialReports } from '../models/finincial-reports';
import { ForBusinesses } from '../models/fot-businesses';
import { ChannelGuides } from '../models/guide';
import { HomePage } from '../models/home-page';
import { News } from '../models/news';
import { PrivacyAndSecurity } from '../models/privacy-and-security';
import { Product } from '../models/product';
import { QuickRenewal } from '../models/quick-renewal';
import { ShareholderNews } from '../models/shareholder-news';
import { TermsOfUse } from '../models/terms-of-use';
import { Validations } from '../models/validations';
import { VideoPage } from '../models/video-page';
import { Winners } from '../models/winners';
import { Career } from '../models/career';
import { CreditReport } from '../models/credit-report';

@Injectable({
  providedIn: 'root'
})
export class StrapiApiService {
  apiURL = environment.strapiUrl;
  constructor(private http: HttpClient) { }
  /*========================================
    CRUD Methods for consuming RESTful API
  =========================================*/
  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    // window.alert(errorMessage);
    return throwError(() => {
      return errorMessage;
    });
  }

  public getImage(url: string): string {
    return this.apiURL + url;
  }

  getNews(): Observable<News> {
    return this.http
      .get<News>(this.apiURL + '/api/Articles?populate=deep')
      .pipe(retry(1), catchError(this.handleError));
  }
  getAboutUs(): Observable<AboutUs> {
    return this.http
      .get<AboutUs>(this.apiURL + '/api/about-us?populate=deep').pipe(retry(1), catchError(this.handleError));

  }

  getTerms(): Observable<TermsOfUse> {
    return this.http
      .get<TermsOfUse>(this.apiURL + '/api/terms-of-use?populate=deep').pipe(retry(1), catchError(this.handleError));
  }

  getCreditReport(): Observable<CreditReport> {
    return this.http
      .get<CreditReport>(this.apiURL + '/api/credit-report?populate=deep').pipe(retry(1), catchError(this.handleError));
  }
  

  getShareholderNews(): Observable<ShareholderNews> {
    return this.http
      .get<ShareholderNews>(this.apiURL + '/api/shareholder-news?populate=deep').pipe(retry(1), catchError(this.handleError));

  }

  getFinancialReports(): Observable<FinancialReports> {
    return this.http
      .get<FinancialReports>(this.apiURL + '/api/finincial-reports?populate=deep').pipe(retry(1), catchError(this.handleError));
  }

  getProduct(product: string): Observable<Product> {
    return this.http
      .get<Product>(this.apiURL + '/api/products?filters[identifier][$eq]=' + product + '&populate=deep').pipe(retry(1), catchError(this.handleError));
  }

  getPrivacyAndSecurity(): Observable<PrivacyAndSecurity> {
    return this.http
      .get<PrivacyAndSecurity>(this.apiURL + '/api/privacy-and-security?populate=deep').pipe(retry(1), catchError(this.handleError));

  }

  getForBusinesses(): Observable<ForBusinesses> {
    return this.http
      .get<ForBusinesses>(this.apiURL + '/api/for-business?populate=deep').pipe(retry(1), catchError(this.handleError));

  }

  getHomePage(): Observable<HomePage> {
    return this.http
      .get<HomePage>(this.apiURL + '/api/home-page?populate=deep').pipe(retry(1), catchError(this.handleError));

  }

  getGuides(): Observable<ChannelGuides> {
    return this.http
      .get<ChannelGuides>(this.apiURL + '/api/channel-guide?populate=deep').pipe(retry(1), catchError(this.handleError));

  }

  getClaims(): Observable<Claim> {
    return this.http
      .get<Claim>(this.apiURL + '/api/claim?populate=deep').pipe(retry(1), catchError(this.handleError));

  }

  getClaimsSection(): Observable<ClaimSection> {
    return this.http
      .get<ClaimSection>(this.apiURL + '/api/claim-section?populate=deep').pipe(retry(1), catchError(this.handleError));

  }

  getQuickrenewalsContent(): Observable<QuickRenewal> {
    return this.http
      .get<QuickRenewal>(this.apiURL + '/api/quick-renewal?populate=deep').pipe(retry(1), catchError(this.handleError));

  }

  getCareerPage(): Observable<Career> {
    return this.http
      .get<Career>(this.apiURL + '/api/career?populate=deep').pipe(retry(1), catchError(this.handleError));
  }
  
  getBranches(): Observable<Branches> {
    return this.http
      .get<Branches>(this.apiURL + '/api/Branches?populate=deep').pipe(retry(1), catchError(this.handleError));

  }

  getVidepPages(): Observable<VideoPage> {
    return this.http
      .get<VideoPage>(this.apiURL + '/api/video-page?populate=deep').pipe(retry(1), catchError(this.handleError));

  }

  getValidations(): Observable<Validations> {
    return this.http
      .get<Validations>(this.apiURL + '/api/basic-of-form-validations?populate=deep').pipe(retry(1), catchError(this.handleError));

  }
  getWinners(): Observable<Winners> {
    return this.http
      .get<Winners>(this.apiURL + '/api/winner?populate=deep').pipe(retry(1), catchError(this.handleError));

  }

}
