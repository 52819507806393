import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { VideoPage } from 'src/app/models/video-page';
import { StrapiApiService } from 'src/app/services/strapi-api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Validations } from 'src/app/models/validations';
declare var window: any; // Declare window as any to access global objects

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.css']
})
export class VideoComponent implements OnInit {
  video!: VideoPage;
  videoForm: any;
  isSubmitted = false;
  validations!: Validations;
  isZoomedOut = false;
  @ViewChild('name') myInput!: ElementRef;

  constructor(
    public strapiApi: StrapiApiService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private renderer: Renderer2
  ) { }

  async ngOnInit() {
    this.route.data.subscribe(async params => {
      // this.title = params['data'].data.attributes.termsTitle;
      //this.terms = params['data'].data.attributes.terms;
      this.video = params['data'];
    });
    const script = document.createElement('script');
    // script.setAttribute('data-entry-id', 'cLQgcJirSQ2vCtoZFhiyUQ');
    script.setAttribute('data-env', 'us01');
    // script.setAttribute('data-apikey', '94NjPSW_RaC-qGF9bmJyQQ');
    script.src = 'https://us01ccistatic.zoom.us/us01cci/web-sdk/video-client.js';
    document.head.appendChild(script);

    this.route.data.subscribe(async (params) => {
      this.validations = params['val'];
    });
    this.videoForm = new FormGroup({
      name: new FormControl('', [
        Validators.required,
        minDigitsValidator(8),
      ]),
    });
  }

  get getLang(): any {
    return localStorage.getItem('lang') ?? "en"; //Get Global Variable Value
  }
  delete() {
    localStorage.removeItem('lang'); //Delete Global Variable
  }
  getAr() {
    localStorage.setItem('lang', 'ar'); //Set Global Variable
  }
  getEn() {
    localStorage.setItem('lang', 'en'); //Set Global Variable
  }
  // Get the button that opens the modal
  showModal = false;
  showHighModal = false;

  // When the user clicks on the button, open the modal
  openModal() {
    this.showModal = true;
    // console.log(this.showModal);    
  }

  // When the user clicks on <span> (x), close the modal
  closeModal() {
    this.showModal = false;
    this.showHighModal = false;
  }


  startVideoCall() {
    this.isSubmitted = true;
    this.renderer.selectRootElement(this.myInput.nativeElement).blur();

    if (this.videoForm.valid) {
      this.closeModal();
      const viewport = document.querySelector('meta[name="viewport"]');
      if (viewport) {
        // Temporarily set scale to 1 (reset zoom level)
        console.log("reset zoom level");
        viewport.setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no');
        this.isZoomedOut = !this.isZoomedOut;
      }

      const entryId = 'cLQgcJirSQ2vCtoZFhiyUQ'; // Replace with actual entry ID
      const videoClient = new window.VideoClient(); // Access VideoClient via window

      videoClient.init({
        entryId,
        name: this.videoForm.get('name').value.toString(),
        userName: this.videoForm.get('name').value.toString(),
      }).then(() => {
        videoClient.startVideo();
        videoClient.on('video-end', () => {
          console.log('video ended');
        });
      }).catch((error: any) => {
        if (error instanceof Error) {
          console.error('Error initializing video client:', error.message);
        } else {
          console.error('An unknown error occurred:', error);
        }
      });
    }
  }

}
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function minDigitsValidator(minDigits: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value ? control.value.toString() : '';
    return value.length >= minDigits ? null : { minDigits: { requiredLength: minDigits, actualLength: value.length } };
  };
}
